/**
 * Blog component
 **/

import React from "react";
import { Link } from "gatsby";
import BlogItem from "./blog-item";
import { Image } from "../../image";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const BlogLayout = ({ gaiaPage }) => {
  return (
    <>
      <section className="position-relative py-6">
        {gaiaPage.title_picture ? (
          <Image
            layout="fullWidth"
            priority={true}
            src={`/_${gaiaPage.title_picture.url}`}
            background={gaiaPage.title_picture.placeholder}
            width={2560}
            alt={gaiaPage.child_pages[0].name}
            className="img-fluid bg-image blog-title-image"
          />
        ) : null}
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="bg-white rounded-lg shadow p-5">
                <div>
                  <strong className="text-uppercase text-secondary d-inline-block mb-2 text-sm">
                    Featured
                  </strong>
                  <h2 className="mb-3">{gaiaPage.child_pages[0]?.name}</h2>
                  <p className="text-muted">
                    {gaiaPage.child_pages[0]?.preview_text}
                  </p>
                  <Link
                    to={gaiaPage.child_pages[0]?.url}
                    className="btn btn-link p-0"
                  >
                    Lue lisää{" "}
                    <FontAwesomeIcon icon={faArrowRight} fixedWidth size="xs" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-6">
        <div className="container">
          <div className="row mb-5">
            {gaiaPage.child_pages.map(item => {
              return (
                <BlogItem
                  key={item.name}
                  title={item.name}
                  img={item.preview_picture}
                  previewText={item.preview_text}
                  to={item.url}
                ></BlogItem>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};
export default BlogLayout;
