/*
Blog item
*/

import React from "react";
import { Link } from "gatsby";
import { Image } from "../../image";

const BlogItem = ({ title, img, previewText, to }) => {
  return (
    <div className="col-lg-4 col-sm-6 mb-4">
      <div className="card shadow border-0 h-100">
        <Link to={to}>
          {img ? (
            <Image
              layout="fixed"
              src={`/_${img.url}`}
              background={img.placeholder}
              width={350}
              height={150}
              alt={title}
              className="img-fluid card-img-top"
            />
          ) : null}
        </Link>
        <div className="card-body card-body-content">
          <h5 className="my-2 card-body-more">
            <Link to={to} className="text-dark">
              {title}
            </Link>
          </h5>
          <p className="my-2 text-muted text-sm card-body-text">
            {previewText}
          </p>
          <Link to={to} className="btn btn-link pl-0">
            Lue lisää
          </Link>
        </div>
      </div>
    </div>
  );
};
export default BlogItem;
