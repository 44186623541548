import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import BlogLayout from "../components/blog/blog-layout";
import { graphql } from "gatsby";

const BlogFrontpage = ({ data: { gaiaPage } }) => (
  <Layout>
    <SEO description={gaiaPage.preview_text} title={gaiaPage.name} />
    <BlogLayout gaiaPage={gaiaPage}></BlogLayout>
  </Layout>
);

export default BlogFrontpage;

export const query = graphql`
  query BlogCategory($id: String) {
    gaiaPage(id: { eq: $id }) {
      name
      preview_text
      title_picture {
        url
        placeholder
      }
      # fields {
      #   title_picture {
      #     url
      #     childImageSharp {
      #       fluid(maxWidth: 2560) {
      #         ...GatsbyImageSharpFluid
      #       }
      #     }
      #   }
      # }
      child_pages {
        name
        url
        preview_text
        preview_picture {
          url
          placeholder
        }
        # fields {
        # preview_picture {
        #   url
        #   childImageSharp {
        #     fixed(width: 350, height: 150) {
        #       ...GatsbyImageSharpFixed
        #     }
        #   }
        # }
        # }
      }
    }
  }
`;
